import { inject } from '@angular/core';
import { ActivatedRoute, CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const _authService = inject(AuthService);
  const _router = inject(Router);

  if (!_authService.isAuth || _authService._tokenExpired) {
    _authService.logout();
    _router.navigate(['/login']);
    return false;
  } else {
    const permissions = _authService.permissions;
    if (
      route.data['permissions'] &&
      (route.data['permissions'] as string[])?.find((permission) =>
        permissions.includes(permission)
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
};
